<template>
    <div class='load-page tw-w-full tw-h-screen tw-flex tw-justify-center tw-items-center tw-bg-gray-100'>
        <div class='tw-relative'>
            <div class='spin '>
            
            </div>
            <h3 class='texto-loader tw-absolute tw-text-xl tw-top-1/2 tw-left-1/2'><strong>Cargando...</strong></h3>
        </div>
        
    </div>
</template>


<script lang='ts'>
import {defineComponent, onMounted} from 'vue'
import axios from '@/plugins/axios';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router'


export default defineComponent({
    setup(){
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        const pedirDatosCliente = async () => {
            try{
                const { cliente } = route.params;
                
                const result = await axios.get(`clientes/configuracion-portal-publico/${cliente}`)
                setTimeout(()=>{

                    if(result.data){
                        localStorage.setItem('cliente', `${cliente}`);
                        window.document.title = "Portal " + result.data.cliente.descripcion
                        store.commit('SET_CONFIG', result.data);
                        return router.push(`/${cliente}/login`);
                    }

                    return router.push('/404')

                },1500)
                
                
            }catch(err){
                console.log(err);
                router.push('/404')
            }
            }

            onMounted(() => {
                pedirDatosCliente();
            })

        return {

        }
    }
})
</script>


<style lang="css">
    .load-page .texto-loader{
        transform:translate(-50%,-50%)
    }
    .load-page .spin{
        width:15rem;
        height:15rem;
        border-radius:100%;
        border:5px solid transparent;
        border-top-color:var(--primary-color);
        transform:rotate(0deg);
        animation: spin 4s linear infinite;
        box-shadow:0 0 5px 5px rgba(0,0,0,.1);
    }
    @keyframes spin {
        from{
            transform:rotate(0deg);
            
        }to{
            transform:rotate(360deg);
            
        }
    }
</style>